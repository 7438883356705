<template>

  <div>
  <!--<v-app>-->

    <!--<v-app-bar-->
      <!--color="primary"-->
      <!--app-->
      <!--flat-->
      <!--dark>-->

      <!--<v-spacer></v-spacer>-->

    <!--</v-app-bar>-->

    <!--<v-main>-->

      <rapi-doc id="rapi-doc"
                ref="rapidoc"
                style="height:100vh; width:100%"
                render-style="read"
                default-api-server="https://api.accesscardnet.com"
                allow-server-selection="false"
                schema-hide-read-only
                fetch-credentials="include"
                theme="light"
                show-header="false"
                anav-bg-color="#1565c0"
                nav-bg-color="#1565c0"
                bg-color="#FAFFFF"
                allow-authentication="false"
                primary-color="#1565c0"
                layout="row">
      <v-btn
          icon
          link
          @click="signout()"
          style="float: right;">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      </rapi-doc>

    <!--</v-main>-->
  </div>

</template>

<script>
import 'rapidoc'
import { mapActions } from 'vuex'


export default {

  data() {
    return {
      apiKey: '-'
    }
  },

  metaInfo() {
    return {
      title: 'Access API Docs',
    }
  },

  methods: {
    ...mapActions('Auth', ['signOut']),
    ...mapActions(['openApiSpec']),

    async signout() {
      await this.signOut()
      return this.$router.push( { name: 'sign-in' } )
    }
  },

  mounted() {
    this.openApiSpec()

    .then(json => {
      //this.apiKey = json.info.jwt
      //delete json.info.jwt
      //console.log(this.apiKey)
//console.log(this.$refs.rapidoc)
      console.log(json)
      this.$refs.rapidoc.loadSpec(json)
    })
  },
}

</script>
